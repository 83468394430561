import I18n from 'I18n';
import { OTHER, SCOPE_GROUP_TOP_LEVEL_GROUPINGS } from '../constants/scopeGroupTopLevelGroupings';
import { getScopeGroupI18nKey } from '../../utils/getScopeGroupI18nKey';
import { scopeNameComparator } from './comparators';
import { HIGHLY_SENSITIVE, SENSITIVE } from '../../constants/SensitiveScopes';
import { extractFQNFromScopeName, getAppObjectScopeGroupDescription, getIsAppObjectScopeGroup } from './appObjects';
export const getScopeGroupBaseKey = name => {
  // If the scope is an app object scope, use the FQN as the base key so all actions are grouped together
  if (getIsAppObjectScopeGroup(name)) {
    return extractFQNFromScopeName(name);
  }

  // Remove .sensitive and .highly_sensitive from the name
  const cleanName = name.replace(new RegExp(`\\.(${SENSITIVE}|${HIGHLY_SENSITIVE})`, 'g'), '');
  // Split the name and remove the last part
  const parts = cleanName.split('.');
  return parts.slice(0, -1).join('.') || name;
};
const isScopeGroupTopLevelGrouping = topLevel => {
  return topLevel in SCOPE_GROUP_TOP_LEVEL_GROUPINGS;
};
const getTopLevelScopeCategory = scope => {
  const topLevel = scope.name.split('.')[0];
  return isScopeGroupTopLevelGrouping(topLevel) ? topLevel : OTHER;
};
const getTranslatedDescriptionOrFallback = scope => {
  if (getIsAppObjectScopeGroup(scope.name)) {
    return getAppObjectScopeGroupDescription(scope);
  }
  return I18n.text(getScopeGroupI18nKey(scope.name, 'description'), {
    defaultValue: scope.longDescription || scope.shortDescription || scope.name
  });
};

/**
 * Transform an array of Scope Groups into a tree object where scope groups have been sorted into
 * categories based on their top-level event (CRM, CMS, Settings, OTHER etc) and then further grouped
 * by similar scope names.
 *
 * For example, 'crm.objects.contacts.read' and 'crm.objects.contacts.write' would be grouped together
 * under the 'crm' category because they share the same base 'crm.objects.contacts'.
 *
 * @param {ScopeGroup[]} scopes - An array of scope groups containing at least the ID and name.
 * @returns {ScopeGroupAuthorizationTree}
 */
const createScopesAuthorizationTree = scopes => {
  // Initialize the result object with all possible categories as empty arrays
  const result = {
    crm: [],
    cms: [],
    settings: [],
    other: []
  };

  // First, sort scopes by name and then group by their top-level category
  const groupedByTopLevel = scopes.sort(scopeNameComparator).reduce((acc, scope) => {
    const category = getTopLevelScopeCategory(scope);

    // Add translated description to the scope
    const scopeWithDescription = Object.assign({}, scope, {
      translatedDescription: getTranslatedDescriptionOrFallback(scope)
    });
    acc[category].push(scopeWithDescription);
    return acc;
  }, {
    crm: [],
    cms: [],
    settings: [],
    other: []
  });

  // Then, for each category, group similar scopes together
  Object.entries(groupedByTopLevel).forEach(([category, _scopes]) => {
    if (isScopeGroupTopLevelGrouping(category)) {
      const groupedByBase = _scopes.reduce((acc, scope) => {
        const baseKey = getScopeGroupBaseKey(scope.name);
        if (!acc[baseKey]) {
          acc[baseKey] = [];
        }
        acc[baseKey].push(scope);
        return acc;
      }, {});

      // Convert the grouped scopes into arrays and add them to the result
      result[category] = Object.values(groupedByBase);
    }
  });

  // Remove empty categories
  Object.entries(result).forEach(([category, groups]) => {
    if (groups.length === 0) {
      delete result[category];
    }
  });
  return result;
};
export default createScopesAuthorizationTree;